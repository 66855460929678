<template>
  <div class="my-vip">
    <div v-if="!showBindCard" class="my-vip-cards">
      <div class="my-vip-header">
        <span style="font-size: 0.12rem; color: #999; margin-right: 0.1rem"
          >权益卡</span
        >
        <span style="font-size: 0.22rem; color: #111; flex: 1">{{
          currentCard.status === 4
            ? currentCard.cash || 0 + "金点"
            : "24小时激活余额"
        }}</span>
        <span
          class="active-text"
          style="font-size: 0.14rem"
          @click="goCardManage"
          >管理</span
        >
      </div>
      <template v-if="cardList && cardList.length">
        <Swiper
          @transtionend="(num) => (currentIndex = num || 0)"
          class="card-swiper"
          :autoPlay="false"
          :showIndicator="true"
        >
          <Slide v-for="(item, index) in cardList" :key="index">
            <card :clickHandler="showQrcode" :value="item"></card>
          </Slide>
        </Swiper>
      </template>
      <div v-else style="text-align: center; padding: 0.5rem 0">暂无卡片</div>
      <div style="display: flex; justify-content: flex-end">
        <!-- <div class="float-btn" @click="goRecharge">充值</div> -->
      </div>
      <div class="my-vip-info">
        <template
          v-if="
            currentCard &&
            currentCard.benefitList &&
            currentCard.benefitList.length
          "
        >
          <div
            v-for="(item, index) in currentCard.benefitList"
            :key="index"
            class="my-vip-info-item"
            @click="goBenefitDetail(item)"
          >
            <div class="my-vip-info-item-left">
              <img :src="item.smallImageUrl" />
            </div>
            <div class="my-vip-info-item-right">
              <div class="my-vip-info-item-right-1">
                <div class="my-vip-info-item-right-1-1">
                  {{ item.goodsSkuName || "--" }}
                </div>
                <div class="my-vip-info-item-right-1-2">
                  {{ item.benefitDescription || "--" }}
                </div>
              </div>
              <div class="my-vip-info-item-right-2">
                <img src="./images/arrow.png" />
              </div>
            </div>
          </div>
        </template>
        <div v-else style="text-align: center; padding: 0.5rem 0">暂无权益</div>
        <pay-drawer
          :cardNo="currentCard.cardNo"
          :visible="payDrawerVisible"
          @onClose="payDrawerVisible = false"
        />
      </div>
    </div>
    <bind-card v-else></bind-card>
  </div>
</template>
<script>
import Card from "@/components/Card";
import { Swiper, Slide } from "vue-swiper-component";
import { getMyCards } from "@/service";
import PayDrawer from "@/components/payDrawer";
import BindCard from "@/views/BindCard";
export default {
  name: "MyVip",
  components: { Card, Swiper, Slide, PayDrawer, BindCard },
  data() {
    return {
      cardList: [],
      currentIndex: 0,
      payDrawerVisible: false,
      showBindCard: false,
    };
  },
  computed: {
    currentCard() {
      return this.cardList[this.currentIndex] || {};
    },
  },
  mounted() {
    this.getMyCards();
    this.$eventBus.$on("refreshCard", () => {
      this.getMyCards();
    });
  },
  methods: {
    goBenefitDetail(item) {
      let images = [];
      try {
        const obj = JSON.parse(item.benefitDetailImg);
        images = (obj || {}).imgs || [];
      } catch (error) {
        //todo
      }
      this.$router.push({
        name: "benefitDetail",
        params: { title: item.goodsSkuName, images },
      });
    },
    showQrcode() {
      if(this.currentCard.status === 4){
        this.$eventBus.$emit("showPay", this.currentCard.id);
        return;
      }
      this.$message.error("当前卡片未激活！");
    },
    goCardManage() {
      if (!this.currentCard || !this.currentCard.cardNo) {
        this.$message.error("未查询到卡片！");
        return;
      }
      const cardNo = this.currentCard.cardNo;
      this.$router.push({ name: "manageHealthCard", params: { cardNo } });
    },
    async getMyCards() {
      const merchantId = this.$store.state.merchantId;
      try {
        const { data: cardList } = await getMyCards(merchantId);
        this.cardList = cardList || [];
      } catch (error) {
        //todo
      }

      if (!this.cardList || !this.cardList.length) {
        this.showBindCard = true;
      }
    },
    goRecharge() {
      if (!this.currentCard.cardNo) {
        this.$message.error("未查询到卡片");
        return;
      }
      this.payDrawerVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.my-vip {
  .my-vip-cards {
    padding: 0.1rem 0.14rem 0.14rem;
  }
  ::v-deep {
    .bind-card {
      padding-top: 0;
    }
    .bc_top {
      box-shadow: none !important;
      height: 0.4rem;
      margin-top: 0.2rem;
    }
    .bc_middle {
      padding-top: 0.1rem !important;
    }
  }
  .my-vip-header {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.1rem;
  }
  .my-vip-info {
    .my-vip-info-item {
      display: flex;
      margin-bottom: 0.2rem;
      .my-vip-info-item-left {
        img {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
      .my-vip-info-item-right {
        flex: 1;
        margin-left: 0.15rem;
        display: flex;
        .my-vip-info-item-right-1 {
          flex: 1;
        }
        .my-vip-info-item-right-1-1 {
          font-size: 0.14rem;
          font-weight: 600;
        }
        .my-vip-info-item-right-1-2 {
          font-size: 0.12rem;
          font-weight: 400;
          margin-top: 0.05rem;
        }
        .my-vip-info-item-right-2 {
          margin-left: 0.3rem;
          padding-top: 0.28rem;
          img {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
      }
    }
  }
}
</style>

<template>
  <a-drawer
    :placement="placement"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="pd_content">
      <div class="pd_top">
        <div>
          <img src="../assets/healthCard.png" alt="" />
        </div>
        <div class="pd_mon">当前余额{{ balance }}金点</div>
        <div class="pd_ctype">{{ cardType }}</div>
      </div>
      <div class="pd_middle">
        <div class="pd_charge">充值</div>
        <a-select default-value="0" style="width: 100%" @change="handleChange">
          <a-select-option
            v-for="(item, index) in countTypes"
            :key="index"
            :value="item.value"
          >
            {{ item.count }}
          </a-select-option>
        </a-select>
        <div class="pd_charge">健康卡允许的单笔最大额度为 1000</div>
      </div>
      <div class="pd_footer">
        <a-button>去支付</a-button>
      </div>
    </div>
  </a-drawer>
</template>
<script>
export default {
  data() {
    return {
      placement: "bottom",
      balance: "9",
      cardType: "健康卡（8646）",
      countTypes: [
        { value: "0", count: "500金点" },
        { value: "1", count: "1000金点" },
        { value: "2", count: "100金点" },
      ],
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    handleChange(value) {
      console.log(value);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ant-drawer-content-wrapper {
  height: unset !important;
  ::v-deep .ant-drawer-body {
    padding: 0.32rem;
  }
}
.pd_top {
  margin-top: 0.14rem;
  text-align: center;
  img {
    width: 30%;
    margin: auto;
  }
  .pd_mon {
    margin: 0.08rem 0;
    font-size: 0.14rem;
    font-weight: 500;
    color: #111111;
  }
  .pd_ctype {
    font-size: 0.11rem;
    font-weight: 400;
    color: #111111;
  }
}
.pd_middle {
  .pd_charge {
    font-size: 0.1rem;
    font-weight: 500;
    color: #999999;
    margin: 0.07rem 0;
  }
  ::v-deep .ant-select-selection {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 0.01rem solid #979797;
    border-radius: 0;
  }
  ::v-deep .ant-select-selection-selected-value {
    font-size: 0.16rem;
    font-weight: 500;
    color: #111111;
  }
  ::v-deep .ant-select-selection__rendered {
    margin-left: 0;
  }
}
.pd_footer {
  text-align: right;
  margin-top: 0.23rem;
  margin-bottom: 0.1rem;
  ::v-deep .ant-btn {
    width: 30%;
    height: 6vh;
    background: #00af66;
    box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    font-size: 0.16rem;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>